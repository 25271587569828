.intro {
  text-align: center;
}

.intro .logo {
  max-width: 20vh;
}

.intro h1 {
	font-size: 8ch;
	font-weight: 600;
}

@media (max-width: 767.98px) {
  .intro h1 {
    font-size: 6ch;
  }
}

.intro h2 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  font-size: 1.5em;
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

@media (max-width: 575.98px) {
  .intro h2 {
    font-size: 1.25em;
  }
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}