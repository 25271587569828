@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');

body {
	min-height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 300% 300%;
	animation: gradient 15s ease infinite;
	font-family: 'Raleway', sans-serif;
	color: #f7f7f7;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
